// Gallery.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaImages } from 'react-icons/fa'; // Import desired icon
import './Gallery_face.css'; 

export default function Gallery() {
  const { id: eventId } = useParams();
  const [images, setImages] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => { 
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          'https://api.hapzea.com/api/v1/user/get-event-images', 
          {
            params: { eventId },
            withCredentials: true
          }
        );
        setImages(response.data.images);
      } catch (error) {
        console.error('Error fetching images:', error);
        setError('Failed to load images.');
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [eventId]);

  if (loading) {
    return <div className="gallery-container-face">Loading...</div>;
  }

  if (error) {
    return <div className="gallery-container-face error">{error}</div>;
  }

  return (
    <div className="gallery-container-face">
      <div className="image-count-face">
        <FaImages />
        <span>Total Images: {images.length}</span>
      </div>
      {images.length > 0 ? (
        <div className="gallery-grid-face">
          {images.map((image, index) => (
            <div className="gallery-item-face" key={index}>
              <img src={image} alt={`Gallery ${index}`} />
            </div>
          ))}
        </div>
      ) : (
        <p>No images available</p>
      )}
    </div>
  );
}
