// Progress.jsx
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './Progress.css';
import { IoImageOutline } from "react-icons/io5";
import { SiTicktick } from "react-icons/si";
import { AiOutlineClose } from "react-icons/ai";
import PropTypes from 'prop-types';

// Utility function to format file names by removing the UUID (ID part) before the hyphen
const formatFileName = (fileName) => {
  // Use regex to detect and remove the UUID-like part
  const uuidPattern = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}-/;
  
  // Replace the UUID part with an empty string
  const cleanedFileName = fileName.replace(uuidPattern, '');

  return cleanedFileName;
};

// Individual Upload Task Component
const UploadTask = React.memo(({ fileName, progress, status }) => {
  return (
<div className='EachTask'>
  <div className='Progressed'>
    <div className='imageIcon'>
      {/* <span><IoImageOutline /></span> */}
    </div>
    <div className='FileDetails'>
      <div className='FileName'>
        <p><span><IoImageOutline /></span>{formatFileName(fileName)}</p>
      </div>
      <div className="progress-bar">
      {status === 'completed' && (
      <div className='Progress-Status'>
        <SiTicktick />
      </div>
    )}
    {status === 'failed' && (
      <div className='Progress-Status failed'>
        <AiOutlineClose />
      </div>
    )}
        <div
          className="progress-bar-inner"
          style={{
            width: `${progress}%`,
            backgroundColor: status === 'failed' ? '#e74c3c' : '#007bff',
          }}
        ></div>
              <div className='progress-text'>
        {progress}% {status === 'failed' && 'Failed'}
      </div>
      </div>
      {/* <div className='progress-text'>
        {progress}% {status === 'failed' && 'Failed'}
      </div> */}
    </div>
    {/* {status === 'completed' && (
      <div className='Progress-Status'>
        <SiTicktick />
      </div>
    )}
    {status === 'failed' && (
      <div className='Progress-Status failed'>
        <AiOutlineClose />
      </div>
    )} */}
  </div>
</div>

  );
});

UploadTask.propTypes = {
  fileName: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

// Main Progress Component
const Progress = () => {
  const uploads = useSelector(state => state.user.uploads);
  const progressRef = useRef(null);

  // Scroll to the bottom when new uploads are added
  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.scrollTop = progressRef.current.scrollHeight;
    }
  }, [uploads]);

  return (
    <div className="progress-container" ref={progressRef}>
      <div className='Progressing'>
        {Object.entries(uploads).map(([fileName, { progress, status }]) => (
          <UploadTask 
            key={fileName}
            fileName={fileName} 
            progress={progress} 
            status={status}
          />
        ))}
        <div className='count'>
        {Object.keys(uploads).length === 0 && (
          <div className='NoUploads'>
            <p>No uploads in progress.</p>
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Progress);
