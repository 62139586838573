import React, { useState, useEffect } from 'react';
import styles from './SubPage.module.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import logtail from '../logtail';
import { useSelector, useDispatch } from 'react-redux';
import { UserActions } from '../Store/userSlice';
import ImageViewer from './ImageViewerComp/ImageViewerComp';
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io"; // Import heart icons
import { IoIosSend } from "react-icons/io";

function SubPage() {
    const dispatch = useDispatch(); 
    const SELECTION = useSelector(state => state.user);
    const { id, folderName, businessName } = useParams();
    const [imagesState, setImagesState] = useState([]);
    const [index, setIndex] = useState(-1);
    const [selectedImages, setSelectedImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [SubmitDisable, setSubmitDisable] = useState({});
    const [favoriteImages, setFavoriteImages] = useState([]); // Centralized state to track favorite images
    const [bride, setBride] = useState('');
    const [groom, setGroom] = useState('');

    useEffect(() => {
        const fetchData = async (id) => {
          try {
            if (id !== undefined) {
              const response = await axios.get(`https://api.hapzea.com/api/v1/user/client/${id}`, {
                withCredentials: true,
              });
              setSubmitDisable({[folderName]: response.data.data.client.PhotoSubmission[folderName]});
              setBride(response.data.data.client.Bride || '');
              setGroom(response.data.data.client.Groom || '');
            }
          } catch (error) {
            console.error('Error fetching client data:', error);
          }
        };
        fetchData(id);
    }, [id, folderName]);

    useEffect(() => {
        const shareMedia = async (id, folderName) => {
            if (id !== undefined) {
                try {
                    const response = await axios.get(
                        `https://api.hapzea.com/api/v1/user/meta_selction_check/${id}`,
                        {
                            params: { subFiles: folderName },
                            withCredentials: true
                        }
                    );
                    const folderNames = response.data.data.folderNames;
                    dispatch(UserActions.userSelectionResponse({ images: folderNames }));
                } catch (error) {
                    console.error('Error sharing media:', error);
                }
            }
        };
        shareMedia(id, folderName);
    }, [id, folderName, dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.hapzea.com/api/v1/user/fetchMedia_filer`, {
                    params: {
                        id: id,
                        main_folder: 'PhotoSelection',
                        sub_folder: folderName,
                    },
                    withCredentials: true,
                });
                const fetchedPhotos = response.data.data.files || [];
                console.log('fetchedPhotos Share:', fetchedPhotos);

                // Separate originals and thumbnails
                const originals = fetchedPhotos.filter(url => url.includes('/originals/'));
                const thumbnails = fetchedPhotos.filter(url => url.includes('/thumbnails/'));

                // Pair originals with their corresponding thumbnails
                const images = originals.map(originalUrl => {
                    const filename = originalUrl.split('/').pop();
                    const thumbnailUrl = thumbnails.find(url => url.endsWith(`thumb_${filename}`));
                    return {
                        original: originalUrl,
                        thumbnail: thumbnailUrl || deriveThumbnailUrl(originalUrl),
                        title: 'Photo',
                        description: 'No description', // Customize as needed
                    };
                });

                setImagesState(images);
                loadSelectedImages();
            } catch (error) {
                logtail.info('Error fetching data:', error);
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id, folderName, reRender]);

    // Helper function to derive thumbnail URL if not provided
    const deriveThumbnailUrl = (originalUrl) => {
        // Example: Replace 'originals' with 'thumbnails/thumb_' in the URL
        // Original: .../originals/_DSC5525.JPG
        // Thumbnail: .../thumbnails/thumb__DSC5525.JPG
        return originalUrl.replace('/originals/', '/thumbnails/thumb_');
    };

    const loadSelectedImages = () => {
        const savedSelections = JSON.parse(localStorage.getItem(`selectedImages_${folderName}`)) || [];
        setSelectedImages(savedSelections);

        const savedFavorites = JSON.parse(localStorage.getItem(`favoriteImages_${folderName}`)) || [];
        setFavoriteImages(savedFavorites);
    };

    const updatePhotoSubmission = async (id) => {
        if (id !== undefined) {
            try {
                const response = await axios.post(`https://api.hapzea.com/api/v1/user/updatePhotoSubmission/${id}`, { select: folderName }, {
                    withCredentials: true
                });
                setSubmitDisable({ [folderName]: response.data.data.PhotoSubmission[folderName] });
            } catch (error) {
                console.error('Error updating photo submission:', error);
            }
        }
    };

    const handleFavoriteClick = (index) => {
        const uniqueKey = `${folderName}_${index}`;
        let updatedFavorites = [...favoriteImages];
        const isFavorited = updatedFavorites.includes(uniqueKey);
        if (isFavorited) {
            updatedFavorites = updatedFavorites.filter((item) => item !== uniqueKey);
        } else {
            updatedFavorites.push(uniqueKey);
        }
        setFavoriteImages(updatedFavorites);
        localStorage.setItem(`favoriteImages_${folderName}`, JSON.stringify(updatedFavorites));
    };

    const handleClickImage = (index) => {
        setIndex(index);
    };

    const clearFavoriteImages = () => {
        // Clear the favoriteImages state
        setFavoriteImages([]);
    
        // Clear the corresponding entry in localStorage
        localStorage.removeItem(`favoriteImages_${folderName}`);
    };

    const handleSendClick = () => {
        if (favoriteImages.length === 0) {
            alert("Please select at least one image.");
        } else {
            console.log(favoriteImages);
            setShowModal(true);
        }   
    };

    const handleCancelClick = () => {
        setShowModal(false);
    };

    const handleSubmitClick = async () => {
        setShowModal(false);
    
        // Map favoriteImages to the actual images
        let selected = favoriteImages.map((fav) => {
            const [folder, index] = fav.split('_');
            return imagesState[parseInt(index)];
        }).filter(image => image !== undefined); // Filter out undefined images
    
        console.log('selected:', selected);
    
        if (selected.length > 0 && id !== undefined) {
            try {
                const response = await axios.post(
                    `https://api.hapzea.com/api/v1/user/meta_selecting/${id}`,
                    {
                        selected: selected,
                        selection: 'user',
                        sub_folder: folderName
                    },
                    {
                        withCredentials: true,
                    }
                );
                updatePhotoSubmission(id);
            } catch (error) {
                console.error('Error sharing media:', error);
                alert("Failed to submit selections. Please try again.");
            }
        }
    
        setReRender(!reRender);
        clearFavoriteImages();
        setSelectedImages([]);
        localStorage.removeItem(`selectedImages_${folderName}`);
    };
    
    const handleDeleteImages = async () => {
        let selected = selectedImages.map((fav) => {
            const [folder, index] = fav.split('_');
            const src = imagesState[parseInt(index)]?.original;
            return src ? src.split('/').pop() : null;
        }).filter(filename => filename !== null);
        
        if (selected.length === 0) {
            alert("Please select at least one image to delete.");
        } else {
            try {
                const response = await axios.post(
                    `https://api.hapzea.com/api/v1/user/deleteImages/${id}`,
                    {
                        sub_folder: folderName,
                        imageFiles: selected
                    },
                    {
                        withCredentials: true,
                    }
                );
                if (response.status === 200) {
                    alert("Selected images deleted successfully.");
                    setReRender(!reRender);
                    setSelectedImages([]);
                    localStorage.removeItem(`selectedImages_${folderName}`);
                }
            } catch (error) {
                console.error('Error deleting images:', error);
                alert("Failed to delete images. Please try again.");
            }
        }
    };

    return (
        <div>
            <div className={styles.stickyOptions}>
                <div className={styles.options}>
                    <div className={styles.selectionBtn}>
                        <div>
                            {!SubmitDisable[folderName] && (
                                <div className={styles.sendbtn} onClick={handleSendClick}>
                                     Send <span><IoIosSend /></span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.image_container}>
                {imagesState.map((slide, index) => (
                    <div
                        key={index}
                        className={`${styles.image} ${selectedImages.includes(`${folderName}_${index}`) ? styles.selected : ''}`}
                        onClick={() => handleClickImage(index)}
                    >
                        <div className={styles.heartIcon} onClick={(e) => {e.stopPropagation(); handleFavoriteClick(index);}}>
                            {favoriteImages.includes(`${folderName}_${index}`) ?  <IoMdHeart size={30} color="red" /> : <IoMdHeartEmpty size={30} color="white" />}
                        </div>
                        <img 
                            src={slide.thumbnail} 
                            alt={`Thumbnail of ${slide.description}`} 
                            loading="lazy" 
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/path/to/placeholder.png'; // Replace with your placeholder image path
                            }}
                        />
                    </div> 
                ))}
            </div>

            {showModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h2>Submit Selection</h2>
                        <p>Are you sure you want to submit your selection?</p>
                        <p className={styles.message}>You cannot select again after you submit</p>
                        <div>
                            <button onClick={handleCancelClick}>Cancel</button>
                            <button onClick={handleSubmitClick}>Submit</button>
                        </div>
                    </div>
                </div>
            )}

            {index >= 0 && (
                <ImageViewer
                    images={imagesState.map((image) => ({
                        url: image.original, // Use original URL for ImageViewer
                        alt: image.description || image.title
                    }))}
                    currentIndex={index}
                    onClose={() => setIndex(-1)}
                    favoriteImages={favoriteImages} // Pass favoriteImages to ImageViewer
                    handleFavoriteClick={handleFavoriteClick} // Pass the handler to ImageViewer
                />
            )}
        </div>
    );
}

export default SubPage;
