import React from 'react';
import gicon from '../img/Gicon.png';
import { useNavigate } from 'react-router-dom';
import styles from './Google_auth.module.css'; // Ensure this path is correct
import axios from 'axios';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode'; // Correct import

function GoogleAuth() {
  const navigate = useNavigate();

  const handleLoginSuccess = async (response) => {
    try {
      const userCredential = jwtDecode(response.credential);
      const { email, sub: googleId } = userCredential;

      // Send the Google credential to the backend
      const serverResponse = await axios.post(
        'https://api.hapzea.com/api/v1/user/googlesignIn',
        {
          email,
          id: googleId,
        },
        { withCredentials: true }
      );

      if (serverResponse.data.token) {
        // User is authenticated, redirect to services
        navigate('/services');
      } else {
        // Handle scenarios where token is not provided
        console.error('Authentication failed:', serverResponse.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Email already exists with a different sign-in method
        alert('An account with this email already exists. Please log in using your password and then link your Google account.');
      } else {
        console.error('Error during Google Sign-In:', error);
      }
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Google Sign-In failed:', error);
    alert('Google Sign-In failed. Please try again.');
  };

  return (
    <GoogleOAuthProvider clientId="494865887043-0gh1rjv4967gff6rfoffem1qp1mqs6fs.apps.googleusercontent.com">
      <div className={styles.container}>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
          useOneTap
          // Optional: Customize the button if needed
        />
      </div>
    </GoogleOAuthProvider>
  );
}

export default GoogleAuth;
