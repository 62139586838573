import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoMdHeartEmpty } from "react-icons/io"; // Import only the empty heart icon
import { MdOutlineFileDownload } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import "./ImageViewer.css";

const ImageViewer = ({ images, currentIndex, onClose, favoriteImages, handleFavoriteClick }) => {                        
  const { folderName } = useParams();
  const [currentImageIndex, setCurrentImageIndex] = useState(currentIndex);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomStyle, setZoomStyle] = useState({});
  const imageContainerRef = useRef(null);
  const thumbnailContainerRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCurrentImageIndex(currentIndex);
    scrollThumbnailsToCurrent();
  }, [currentIndex]);

  useEffect(() => {
    scrollThumbnailsToCurrent();
  }, [currentImageIndex]);

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollThumbnailsToCurrent();
    }, 100);  // 100ms delay, adjust if needed

    return () => clearTimeout(timer);
  }, [currentImageIndex]);

  useEffect(() => {
    const handleResize = () => {
      scrollThumbnailsToCurrent();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const container = imageContainerRef.current;
    if (container) {
      container.scrollLeft = currentImageIndex * container.offsetWidth;
    }

    setTimeout(() => setLoading(false), 300); // Simulate image loading

    scrollThumbnailsToCurrent();
  }, [currentImageIndex]);

  const handlePrevious = () => {
    setCurrentImageIndex(prev => {
      const newIndex = prev === 0 ? images.length - 1 : prev - 1;
      setTimeout(scrollThumbnailsToCurrent, 50);
      return newIndex;
    });
  };

  const handleNext = () => {
    setCurrentImageIndex(prev => {
      const newIndex = prev === images.length - 1 ? 0 : prev + 1;
      setTimeout(scrollThumbnailsToCurrent, 50);
      return newIndex;
    });
  };

  
  const handleDownload = () => {
    // Ensure the current image index is valid
    
    if (currentImageIndex < 0 || currentImageIndex >= images.length) {
        console.error("Invalid image index:", currentImageIndex);
        return;
    }

    const image = images[currentImageIndex];
    
    // console.log('uuuuuuuuurl',image.url);
    // Check if the image is defined
    if (!image || !image.url) {
        console.error("Image or image URL is undefined for index:", currentImageIndex);
        return;
    }

    const url = image.url.replace('https://storage.cloud.google.com/', 'https://storage.googleapis.com/');
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = downloadUrl;
            a.download = image.alt || `image-${currentImageIndex + 1}.jpg`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
            document.body.removeChild(a);
        })
        .catch((error) => console.error("Error downloading the image:", error));
};

  

  const handleZoomToggle = (e) => {
    if (isZoomed) {
      setIsZoomed(false);
      setZoomStyle({});
    } else {
      const rect = e.target.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;
      const xPercent = (offsetX / rect.width) * 100;
      const yPercent = (offsetY / rect.height) * 100;

      setZoomStyle({
        transformOrigin: `${xPercent}% ${yPercent}%`,
        transform: "scale(2)",
      });
      setIsZoomed(true);
    }
  };

  const scrollThumbnailsToCurrent = () => {
    const thumbnailContainer = thumbnailContainerRef.current;
    if (!thumbnailContainer) return;

    const thumbnails = Array.from(thumbnailContainer.querySelectorAll('img'));
    const activeThumbnail = thumbnails[currentImageIndex];
    if (!activeThumbnail) return;

    const containerWidth = thumbnailContainer.clientWidth;
    const thumbnailWidth = activeThumbnail.offsetWidth;
    const containerScrollWidth = thumbnailContainer.scrollWidth;

    // Calculate the ideal centered position
    let scrollPosition = activeThumbnail.offsetLeft - (containerWidth / 2) + (thumbnailWidth / 2);

    // Ensure we don't scroll past the start
    scrollPosition = Math.max(0, scrollPosition);

    // Ensure we don't scroll past the end
    scrollPosition = Math.min(containerScrollWidth - containerWidth, scrollPosition);

    // Use requestAnimationFrame for smooth scrolling
    requestAnimationFrame(() => {
      thumbnailContainer.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    });
  };

  const Onhandleclose = () => {
    onClose(favoriteImages); // Ensure the onClose returns the updated favoriteImages array
  };

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
    // Add a slight delay to ensure the DOM has updated
    setTimeout(scrollThumbnailsToCurrent, 50);
  };

  if (loading) {
    return <div className="loader"></div>;
  }

  return (
    <div className="image-viewer">
      <div className="gallary-options">
        <div className="image-gallery-icons">
          <button
            className={`favorite-button ${favoriteImages.includes(`${folderName}_${currentImageIndex}`) ? "active" : ""}`}
            onClick={() => handleFavoriteClick(currentImageIndex)}
          >
            <IoMdHeartEmpty className="heart-icon" />
          </button>

          <button className="upload-button" onClick={handleDownload}>
            <MdOutlineFileDownload />
          </button>

          <button className="close-button" onClick={Onhandleclose}>
            <IoMdClose />
          </button>
        </div>
      </div>

      <div className="image-container" ref={imageContainerRef}>
        {images[currentImageIndex] && (
          <div key={currentImageIndex} className="image-item active">
            <img
              src={images[currentImageIndex].url}
              alt={images[currentImageIndex].alt}
              onClick={handleZoomToggle}
              className="image"
              style={isZoomed ? zoomStyle : {}}
            />
          </div>
        )}
      </div>

      <div className="thumbnail-container" ref={thumbnailContainerRef}>
        <div className="thumbnail-wrapper">
          {images.map((thumb, idx) => (
            <img
              key={idx}
              src={thumb.url}
              alt={`Thumbnail ${idx + 1}`}
              className={`thumbnail ${idx === currentImageIndex ? "active" : ""}`}
              onClick={() => handleThumbnailClick(idx)}
            />
          ))}
        </div>
      </div>

      <div className="controls">
        <button className="prev-button" onClick={handlePrevious}>
          <FaChevronLeft />
        </button>
        <button className="next-button" onClick={handleNext}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default ImageViewer;