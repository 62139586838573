// Login.js

import React, { useState } from 'react';
import styles from './Login.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { UserActions } from '../Store/userSlice';
import logtail from '../logtail';
import GoogleAuth from './Google_auth';
import Line from './Line';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailvalid, setEmailvalid] = useState('');
  const [passwordvalid, setPasswordvalid] = useState('');
  const [commonTrigger, setcommonTrigger] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Prevent multiple submissions

  const signinemail = (e) => {
    setEmail(e.target.value);
  };

  const signinpassword = (e) => {
    setPassword(e.target.value);
  };

  const loginfun = async (e) => {
    if (e) e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    try {
        const response = await axios.post(
            'https://api.hapzea.com/api/v1/user/login',
            {
                email,
                password
            },
            { withCredentials: true }
        );
        logtail.info('Response Headers:', response.headers);
         

      if (response.data.token) {
        dispatch(UserActions.getMe(response.data.user));
        logtail.info('Front end token:', response.data.token);
        Cookies.set('jwtToken', response.data.token);
        logtail.info('Navigating to services');
        navigate('/services');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const message = error.response.data.message;
        console.log(message);
        if (message === 'Please provide email and password') {
          setcommonTrigger(message);
        } else if (message === 'Incorrect email') {
          setEmailvalid(message);
        } else if (message === 'Incorrect password') {
          setPasswordvalid(message);
        }
      } else {
        console.error('An unexpected error occurred:', error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      loginfun();
    }
  };

  const handleBlur = () => {
    setEmailvalid('');
    setPasswordvalid('');
    setcommonTrigger('');
  };

  return (
    <div className={styles.loginBoxGlobal}>
      <div className={styles.Logintext}>
        <p>Login</p>
      </div>
      {commonTrigger && (
        <div>
          <p className={styles.invalidStatus}>{commonTrigger}</p>
        </div>
      )}
      <div className={styles.glass_form}>
        <div className={styles.input_group}>
          <label>
            <p>Email</p>
          </label>
          {emailvalid && <p className={styles.invalidStatus}>{emailvalid}</p>}
          <input
            type="text"
            placeholder=""
            className={styles.input_field}
            onChange={signinemail}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </div>

        <div className={styles.input_group}>
          <label>
            <p>Password</p>
          </label>
          {passwordvalid && <p className={styles.invalidStatus}>{passwordvalid}</p>}
          <input
            type="password"
            placeholder=""
            className={styles.input_field}
            onChange={signinpassword}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </div>

        <div className={styles.forgotpass}>
          <a href="#">
            <p>Forgot password?</p>
          </a>
        </div>
        <button
          className={styles.login_button}
          onClick={loginfun}
          disabled={isSubmitting} // Disable button while submitting
        >
          {isSubmitting ? 'Logging in...' : 'Login'}
        </button>
        <div className={styles.signupOption}>
          <Line content="or login with" />
        </div>
        <div>
          <GoogleAuth />
        </div>
        <div className={styles.newuser}>
          <p>New User?</p>
          <Link to="/signup">Sign Up</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
