// src/store.js

import { configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import clientSlice from './clientSlice'; // Import the clientSlice

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    clients: clientSlice.reducer, // Add the clients reducer
  },
});

export default store;
