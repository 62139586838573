import React from 'react';
import styles from './UserProfile.module.css';
import photo2 from '../img/pexels-stefan-stefancik-91227.jpg';
import { MdOutlineEmail } from "react-icons/md";
import { CiPhone } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { TiSocialFacebook } from "react-icons/ti";
import { FaInstagram } from "react-icons/fa";
import { CiLink } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import photo3 from '../img/user.png'; 
import axios from 'axios';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import logtail from '../logtail';

function UserProfile() {
    
    const navigate = useNavigate();
    const userStatus = useSelector(state => state.user.me);
    console.log(userStatus);

    const Logout = () => {
        axios.get('https://api.hapzea.com/api/v1/user/logout', { withCredentials: true })
            .then(response => {
                console.log('Logout successful');
                navigate('/login');
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });  
    }

    // Optional: Handle loading state
    if (!userStatus) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.userProfile}>
            <div className={styles.container}>
                <div className={styles.backD}>
                    {/* <div className={styles.edit}><FaRegEdit /></div> */}
                    <div className={styles.backDContent}>
                        <div className={styles.ProfilePhoto}>
                            <div className={styles.profilePic}>
                                <img src={photo3} alt='Profile' />
                            </div>
                            <p>{userStatus.businessName || 'No Business Name'}</p>
                            <ul>
                                {userStatus.socialProfiles?.facebook && (
                                    <li>
                                        <a href={userStatus.socialProfiles.facebook} target="_blank" rel="noopener noreferrer">
                                            <TiSocialFacebook className={styles.profileIcon}/>
                                        </a>
                                    </li>
                                )}
                                {userStatus.socialProfiles?.instagram && (
                                    <li>
                                        <a href={userStatus.socialProfiles.instagram} target="_blank" rel="noopener noreferrer">
                                            <FaInstagram className={styles.profileIcon}/>
                                        </a>
                                    </li>
                                )}
                                {userStatus.website && (
                                    <li>
                                        <a href={userStatus.website} target="_blank" rel="noopener noreferrer">
                                            <CiLink className={styles.profileIcon}/>
                                        </a>
                                    </li>
                                )}
                                {userStatus.socialProfiles?.youtube && (
                                    <li>
                                        <a href={userStatus.socialProfiles.youtube} target="_blank" rel="noopener noreferrer">
                                            <CiYoutube className={styles.profileIcon}/>
                                        </a>
                                    </li>
                                )}
                                {userStatus.socialProfiles?.linkedin && (
                                    <li>
                                        <a href={userStatus.socialProfiles.linkedin} target="_blank" rel="noopener noreferrer">
                                            <CiLinkedin className={styles.profileIcon}/>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className={styles.Contacts}>
                            {/* <p>Contacts</p> */}
                            <ul>
                                <li>
                                    <span><MdOutlineEmail /></span>
                                    {userStatus.email || 'No Email Provided'}
                                </li>
                                <li>
                                    <span><CiPhone /></span>
                                    {userStatus.mobile || 'No Mobile Number'}
                                </li>
                                <li>
                                    <span><CiLocationOn /></span>
                                    {userStatus.address || 'No Address Provided'}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.Logout} onClick={Logout}>Log Out</div>
                </div>
            </div>
        </div>
    );
}

export default UserProfile;
