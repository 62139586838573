import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Gallery } from 'react-grid-gallery';
import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'yet-another-react-lightbox';
import styles from './MainGallary.module.css';
import { MdDelete } from "react-icons/md";
import {
    Fullscreen,
    Thumbnails,
    Zoom,
} from 'yet-another-react-lightbox/plugins';
import Counter from "yet-another-react-lightbox/plugins/counter";
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import "yet-another-react-lightbox/plugins/counter.css";

function MainGallary() {
    const { clientName, eventDate, medium, select, id } = useParams();
    const [imagesState, setImagesState] = useState([]);
    const hasSelected = imagesState.some((image) => image.isSelected);
    const [index, setIndex] = useState(-1);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedOption, setSelectedOption] = useState('option1');
    const [bride, setBride] = useState('');
    const [groom, setGroom] = useState('');
    const [fileUpdate, setfileUpdate] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            console.log('Fetching data with params:', { clientName, eventDate, medium, select, id });
            try {
                let main;
                if (medium === 'choose') {
                    main = 'PhotoSelection';
                }
                if (medium === 'set') {
                    main = 'Album';
                }
                const response = await axios.get(`https://api.hapzea.com/api/v1/user/fetchMedia`, {
                    params: {
                        id: id,
                        main_folder: main,
                        sub_folder: select,
                    },
                    withCredentials: true,
                });
                const fetchedPhotos = response.data.data.files || [];
                console.log('fetchedPhotos:', fetchedPhotos);

                // Separate originals and thumbnails
                const originals = fetchedPhotos.filter(url => url.includes('/originals/'));
                const thumbnails = fetchedPhotos.filter(url => url.includes('/thumbnails/'));

                // Pair originals with their corresponding thumbnails
                const images = originals.map(originalUrl => {
                    const filename = originalUrl.split('/').pop();
                    const thumbnailUrl = thumbnails.find(url => url.endsWith(`thumb_${filename}`));
                    return {
                        original: originalUrl,
                        thumbnail: thumbnailUrl || deriveThumbnailUrl(originalUrl),
                        title: 'Photo',
                        description: 'No description', // Customize as needed
                    };
                });

                setImagesState(images);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        fetchClientDta(id);
    }, [id, medium, select, fileUpdate]);

    // Helper function to derive thumbnail URL if not provided
    const deriveThumbnailUrl = (originalUrl) => {
        // Example: Replace 'originals' with 'thumbnails/thumb_' in the URL
        // Original: .../originals/_DSC5525.JPG
        // Thumbnail: .../thumbnails/thumb__DSC5525.JPG
        return originalUrl.replace('/originals/', '/thumbnails/thumb_');
    };

    const fetchClientDta = async (id) => {
        try {
            if (id !== undefined) {
                const response = await axios.get(`https://api.hapzea.com/api/v1/user/client/${id}`, {
                    withCredentials: true,
                });

                console.log('client using ID');
                console.log(response.data.data.client.Bride);
                if (response.data.data.client.Bride) {
                    setBride(response.data.data.client.Bride);
                    setGroom(response.data.data.client.Groom);
                }
            }
        } catch (error) {
            console.error('Error fetching client data:', error);
        }
    };

    const handleSelectImage = (index) => {
        const isSelected = selectedImages.includes(index);
        if (isSelected) {
            setSelectedImages(selectedImages.filter((item) => item !== index));
        } else {
            setSelectedImages([...selectedImages, index]);
        }
    };

    const handleSelect = (index) => {
        const nextImages = imagesState.map((image, i) =>
            i === index ? { ...image, isSelected: !image.isSelected } : image
        );
        setImagesState(nextImages);
    };

    const handleSelectAllClick = () => {
        const nextImages = imagesState.map((image) => ({
            ...image,
            isSelected: !hasSelected,
        }));
        setImagesState(nextImages);
    };

    const handleClickImage = (index) => {
        setIndex(index);
    };

    const handleViewOption = () => {
        // Do something with the selected option
        console.log("Selected option:", selectedOption);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value === 'option3') {
            setSelectedImages([...Array(imagesState.length).keys()]);
        } else {
            setSelectedImages([]);
        }
    };

    const handleDeleteImages = async () => {
        console.log(selectedImages);
        console.log('DELETE CALL');
        let main;
        if (medium === 'choose') {
            main = 'PhotoSelection';
        }
        if (medium === 'set') {
            main = 'Album';
        }
        let selected = [];
        selectedImages.forEach((i) => {
            const src = imagesState[i].original;
            const filename = src.split('/').pop();
            selected.push(filename);
        });
        console.log(id, select, selected);
        if (selected.length === 0) {
            alert("Please select at least one image to delete.");
        } else {
            try {
                const response = await axios.post(
                    `https://api.hapzea.com/api/v1/user/deleteImages/${id}`,
                    {
                        sub_folder: select,
                        mainFile: main,
                        imageFiles: selected
                    },
                    {
                        withCredentials: true,
                    }
                );
                if (response.status === 200) {
                    setfileUpdate(!fileUpdate);
                    alert("Selected images deleted successfully.");
                    setSelectedImages([]);
                }
            } catch (error) {
                console.error('Error deleting images:', error);
                alert("Failed to delete images. Please try again.");
            }
        }
    };

    const isSelectOption = selectedOption === 'option1';
    const isViewOption = selectedOption === 'option2';

    return (
        <>
            <div className={styles.Header}>
                <div className={styles.topHead}>
                    <p>{`${bride} & ${groom}`}</p>
                </div>
                <div className={styles.selectionBtn}>
                    <select value={selectedOption} onChange={handleSelectChange}>
                        <option value="option1">Select</option>
                        <option value="option2">View</option>
                        <option value="option3">Select All</option>
                    </select>
                    <div className={styles.deletebtn} onClick={handleDeleteImages}>
                        <MdDelete style={{ color: 'red', fontSize: '24px', marginLeft: '10px', cursor: 'pointer' }} />
                    </div>
                </div>
            </div>
            <div className={styles.image_container}>
                {imagesState.map((slide, index) => (
                    <div
                        key={index}
                        className={`${styles.image} ${selectedImages.includes(index) ? styles.selected : ''}`}
                        onClick={
                            isSelectOption
                                ? () => handleSelectImage(index)
                                : isViewOption
                                    ? () => handleClickImage(index)
                                    : null
                        }
                    >
                        {selectedImages.includes(index) && (
                            <div className={styles.checkmark}>
                                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="#07a02b" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM11.7682 15.6402L16.7682 9.64018L15.2318 8.35982L10.9328 13.5186L8.70711 11.2929L7.29289 12.7071L10.2929 15.7071L11.0672 16.4814L11.7682 15.6402Z" fill="#07a02b" />
                                </svg>
                            </div>
                        )}
                        <img 
                            src={slide.thumbnail} 
                            alt={slide.description} 
                            loading="lazy" 
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/path/to/placeholder.png'; // Replace with your placeholder image path
                            }}
                        />
                    </div>
                ))}
            </div>

            <Lightbox
                plugins={[Counter, Fullscreen, Zoom, Thumbnails]}
                index={index}
                close={() => setIndex(-1)}
                open={index >= 0}
                slides={imagesState.map((slide) => ({
                    src: slide.original, // Use original URL for lightbox
                    title: slide.title,
                    description: slide.description,
                }))}
            />
        </>
    );
}

export default MainGallary;
