import React, { useState } from 'react';
import SelectBox from './SelectBox';
import DatePIck from './DatePIcker';
import { Link } from 'react-router-dom';
import { BsPersonGear } from 'react-icons/bs';
import styles from './EventForm.module.css';
import './phone.css';
import SubmitButton from './SubmitButton';
import 'react-phone-input-2/lib/style.css';
import Source from './Source';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyComponent = (props) => {
  const [selectedOption, setSelectedOption] = useState({ Event_Category: '' });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSource, setSelectedSource] = useState({ Source: '' });
  const [Groom, setGroom] = useState({ Groom: '' });
  const [Bride, setBride] = useState({ Bride: '' });
  const [Event, setEvent] = useState({ Event_Name: '' });
  const [Venue, setVenue] = useState({ Venue: '' });
  const [ClientName, setClientName] = useState({ Client_Name: '' });
  const [Email, setEmail] = useState({ Email: '' });
  const [Date, setDate] = useState({ Date: '' });
  const [Mob, setMob] = useState('');

  const excludedCategories = ['Wedding', 'Engagement', 'Couple Shoot'];

  const handleSelectChange = (selectedValue) => {
    setSelectedOption({ Event_Category: selectedValue });
    console.log(selectedOption);
  };

  const groomName = (e) => {
    console.log(e.target.value);
    setGroom({ Groom: e.target.value });
  };

  const brideName = (e) => {
    setBride({ Bride: e.target.value });
  };

  const EventName = (e) => {
    setEvent({ Event_Name: e.target.value });
  };

  const VenueName = (e) => {
    setVenue({ Venue: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const emailing = (e) => {
    setEmail({ Email: e.target.value });
  };

  const clientName = (e) => {
    setClientName({ Client_Name: e.target.value });
  };

  const Dating = (selectedDate) => {
    setDate({ Date: selectedDate });
    console.log('Event Form');
    console.log(selectedDate);
  };

  const handleSourceChange = (source) => {
    setSelectedSource({ Source: source });
  };

  const cancelform = () => {
    props.Oncancel();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      ...selectedOption,
      ...Groom,
      ...Bride,
      ...Event,
      ...Venue,
      ...ClientName,
      ...Email,
      ...Date,
      ...selectedSource,
      Phone: phoneNumber,
    };
    console.log(formData);
    try {
      const response = await axios.post('https://api.hapzea.com/api/v1/user/create/client', formData, { withCredentials: true });
      console.log(response.data);
      console.log('Done form ClientSide');
      props.Onsub();
      notifySuccess();
    } catch (error) {
      notifyError();
      console.error(error);
      console.log('invalid');
    } finally {
      setIsLoading(false); // Stop loading animation
    }
  };

  const notifySuccess = () =>
    toast.success('Successfully created client!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const notifyError = () =>
    toast.error('Failed to create client', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  return (
    <div className={styles.wholeForm}>
      <div>
        <h2>Projects</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.eventinfo}>
          <p>Event Information</p>
        </div>
        <div className={styles.selection}>
          <label className={styles.eventLabel}>Event Category</label>
          <SelectBox name="Ben" onOptionSelect={handleSelectChange} />
          <div className={styles.spacing}></div>

          {excludedCategories.includes(selectedOption['Event_Category']) ? (
            <>
              <div className={styles.dateflex}>
                <div className={styles.eventNameInput}>
                  <label className={styles.eventLabel}>Groom Name*</label>
                  <input
                    className={styles.userInput}
                    type="text"
                    id="groomName"
                    name="Groom"
                    onChange={groomName}
                    placeholder="e.g. John"
                    required
                  />
                </div>
                <div className={styles.eventNameInput}>
                  <label className={styles.eventLabel}>Bride Name</label>
                  <input
                    className={styles.userInput}
                    type="text"
                    id="brideName"
                    name="Bride"
                    onChange={brideName}
                    placeholder="e.g. Angelina"
                  />
                </div>
              </div>
              <div className={styles.dateflex}>
                <div className={styles.eventNameInput}>
                  <label className={styles.eventLabel}>Venue Address</label>
                  <input
                    className={styles.userInput}
                    type="text"
                    id="venueAddress"
                    onChange={VenueName}
                    name="Venue"
                    placeholder="e.g. The Leela Palace Bangalore"
                    required
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.dateflex}>
                {/* Conditionally render "Event Name" */}
                {!excludedCategories.includes(selectedOption['Event_Category']) && (
                  <div className={styles.eventNameInput}>
                    <label className={styles.eventLabel}>Event Name</label>
                    <input
                      className={styles.userInput}
                      type="text"
                      id="eventName"
                      name="Event_Name"
                      onChange={EventName}
                      placeholder="e.g. Andrea"
                      required
                    />
                  </div>
                )}
                <div className={styles.eventNameInput}>
                  <label className={styles.eventLabel}>Venue Address</label>
                  <input
                    className={styles.userInput}
                    type="text"
                    id="venueAddress"
                    onChange={VenueName}
                    name="Venue"
                    placeholder="e.g. The Leela Palace Bangalore"
                    required
                  />
                </div>
              </div>
            </>
          )}
          <div className={styles.dateflex}>
            <div className={styles.DateTime}>
              <DatePIck Ondate={Dating} />
            </div>
            {/* <div className={styles.additional_info}>
              <Link>+ ADDITIONAL INFORMATION</Link>
            </div> */}
          </div>
        </div>
        <div className={styles.host}>
          <BsPersonGear style={{ fontSize: '25px', color: '#696969' }} />
          <p>Host</p>
        </div>
        {/* Host fields (optional) */}
        <div className={styles.dateflex}>
          <div className={styles.hostInput}>
            <label className={styles.eventLabel}>Email address</label>
            <input
              className={styles.userInput}
              type="email"
              id="email"
              onChange={emailing}
              name="Email"
              placeholder="e.g. rohan@x.com"
              required
            />
          </div>
        </div>
        {/* Source field (optional) */}
        <div className={styles.eventformbtns}>
          <div className={styles.btnContainer}>
            <button
              type="button"
              className={styles.canceleventformbtn}
              onClick={cancelform}
            >
              Cancel
            </button>
            <SubmitButton
              loading={isLoading}
              className={styles.submiteventformbtn}
            >
              Submit
            </SubmitButton>
          </div>
        </div>
      </form>
      {/* Toast Container for Notifications */}
      <ToastContainer />
    </div>
  );
};

export default MyComponent;
