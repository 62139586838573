import React, { useEffect, useRef, useState } from 'react';
import './ImageUpload.css';
import { IoIosCloudUpload } from 'react-icons/io';
import axios from 'axios';
import uploadIcon from '../../img/upload.svg';
import socket from '../../socket'; // Adjust the path as necessary
import { useParams } from 'react-router-dom';

export default function ImageUpload() {
  const fileInputRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const { id: eventId } = useParams();

  useEffect(() => {
    // Listen for upload progress events
    socket.on('uploadProgress', ({ progress }) => {
      setUploadProgress((prevProgress) => {
        // Debounce the progress update to avoid excessive rerenders
        return Math.max(prevProgress, progress);
      });
    });

    socket.on('uploadComplete', ({ message }) => {
      setUploading(false);
      console.log(message);
      // Optionally fetch and display images here
    });

    socket.on('uploadError', ({ message }) => {
      setUploading(false);
      console.error(message);
    });

    // Cleanup on component unmount
    return () => {
      socket.off('uploadProgress');
      socket.off('uploadComplete');
      socket.off('uploadError');
    };
  }, []);

  // Function to handle single request upload with multiple files
  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);

    if (files.length > 0) {
      setUploading(true);
      setUploadProgress(0); // Reset progress

      const formData = new FormData();
      files.forEach((file) => {
        formData.append('images', file); // 'images' should match the Multer field name
      });

      try {
        const response = await axios.post(
          'https://api.hapzea.com/api/v1/user/upload-images',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            params: {
              socketId: socket.id, // Pass the socket ID as a query parameter
              eventId: eventId,   // Use eventId from useParams
            },
            withCredentials: true,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress(percentCompleted);
            },
          }
        );
        console.log('Upload successful:', response.data);
        setUploading(false);
      } catch (error) {
        console.error('Error uploading files:', error);
        setUploading(false);
      }
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="content-section">
      {uploading && (
        <div className="upload-progress">
          <p>Uploading: {uploadProgress}%</p>
        </div>
      )}

      <div className="upload-box">
        <img className="uploadIcon" src={uploadIcon} alt="Upload Icon" />
        <p className="upload-text">Drag and Drop Images here</p>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          multiple
          onChange={handleFileChange}
        />

        <button className="upload-btn" onClick={handleUploadClick}>
          <IoIosCloudUpload className="upload-icon" />
          Upload
        </button>
      </div>
    </div>
  );
}
