import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './FormPage.css';
import { FaUser, FaPhone, FaEnvelope, FaArrowRight } from 'react-icons/fa';

function FormPage() {
  const [name, setName] = useState('');
  // const [phone, setPhone] = useState(''); // Commented out phone state
  const [email, setEmail] = useState(''); // Added email state
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // navigate('selfie', { state: { name, phone } }); // Commented out navigation with phone
    navigate('selfie', { state: { name, email } }); // Updated navigation with email
  };

  return (
    <div className="form-page">
      <div className="form-container">
        <h1 className="form-title">Welcome!</h1>
        <form onSubmit={handleSubmit} className="user-form">
          <div className="input-group">
            <FaUser className="input-icon" />
            <input
              type="text"
              placeholder="Enter Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              aria-label="Name"
            />
          </div>

          {/*
          <div className="input-group">
            <FaPhone className="input-icon" />
            <input
              type="tel"
              placeholder="Enter Your Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              pattern="^\+?[1-9]\d{1,14}$"
              aria-label="Phone Number"
            />
          </div>
          */}

          <div className="input-group">
            <FaEnvelope className="input-icon" />
            <input
              type="email"
              placeholder="Enter Your Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email Address"
            />
          </div>

          <button type="submit" className="submit-button" aria-label="Proceed to Selfie">
            <FaArrowRight className="button-icon" />
            Next
          </button>
        </form>
      </div>
    </div>
  );
}

export default FormPage;
