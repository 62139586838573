import React, { useState, useEffect } from 'react';
import styles from './ClientList.module.css';
// import { AiOutlineDown } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineDown, AiOutlineDelete } from 'react-icons/ai';
function ClientList() {
    const [listShow, setListShow] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [clientListLength, setClientListLength] = useState();
    const [ClickedList, setClickedList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [eventToDelete, setEventToDelete] = useState(null); // Event ID to delete
    const [modalOpen, setModalOpen] = useState(false); // Modal state for confirmation
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.hapzea.com/api/v1/user/client/sorted', {
                    withCredentials: true,
                });

                const clientDetails = response.data.data.clients.map((group) => {
                    return group.map((client) => {
                        const dateObject = new Date(client.Date);
                        const monthNames = [
                            'January', 'February', 'March', 'April', 'May', 'June',
                            'July', 'August', 'September', 'October', 'November', 'December'
                        ];
                        const year = dateObject.getFullYear();
                        const month = monthNames[dateObject.getMonth()];
                        const day = dateObject.getDate();
                        let hours = dateObject.getHours();
                        const minutes = dateObject.getMinutes();
                        const seconds = dateObject.getSeconds();

                        const period = hours < 12 ? 'AM' : 'PM';
                        hours = hours % 12 || 12;

                        client.Date = `${day} ${month} ${year}`;
                        client.Time = `${hours}:${minutes}:${seconds} ${period}`;

                        return client;
                    });
                });

                setClientListLength(clientDetails.length);
                console.log('clientDetails #######', clientDetails);
                setClientList(clientDetails);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const toggleList = (index) => {
        setClickedList(index);
        setDropdown(!dropdown);
        setListShow(!listShow);
    };

    const confirmDelete = (clientId) => {
        console.log("Delete confirmation for client: ", clientId); // Test log
        setEventToDelete(clientId);
        setModalOpen(true); // Open confirmation modal
    };


    const handleDelete = async () => {
        console.log('Deleting event with ID: ', eventToDelete); // Test log
        try {
            // Make the API call to delete the event
            await axios.delete(`https://api.hapzea.com/api/v1/user/client/delete/${eventToDelete}`, {
                withCredentials: true,
            });
            
            // Update the clientList state:
            // 1. Filter out the deleted client from each group.
            // 2. Remove any groups that become empty after the deletion.
            setClientList((prevList) => 
                prevList
                    .map(group => group.filter(client => client._id !== eventToDelete))
                    .filter(group => group.length > 0)
            );
    
            setModalOpen(false); // Close the modal after deletion
            setEventToDelete(null); // Reset the eventToDelete
        } catch (error) {
            console.error('Error deleting client:', error);
        }
    };
    

    const toOperation = (client) => {
        const { Groom, Bride, EventName, Time, ClientName, Date, Venue, magicLink, _id } = client;
        navigate(`/events/${_id}`, {
            state: { Groom, Bride, EventName, Time, Date, ClientName, Venue, magicLink, _id },
        });
    };

    return (
        <>
            {loading ? (
                <div className={styles.ldsDefault}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            ) : (
                clientList.map((group, index) => (
                    <div className={styles.outerbody} key={index} onClick={() => toggleList(index)}>
                        <div className={styles.ClientListouter}>
                            <div className={styles.eventDate} onClick={() => toggleList(index)}>
                                <p>{`${group[0].Date}`} <span className={styles.numProjects}>{`${group.length}`} Projects</span></p>
                                <span onClick={() => toggleList(index)} className={!dropdown && index === ClickedList ? styles.dropdownlistActive : styles.dropdownlist}><AiOutlineDown /></span>
                            </div>
                            <div className={!listShow && index === ClickedList ? styles.userListShow : styles.usersList}>
                                {group.map((client, i) => (
                                    <div key={i} className={styles.clientName} onClick={() => toOperation(client)}>
                                        <p>
                                            {client.Groom && client.Bride
                                                ? `${client.Groom} & ${client.Bride}`
                                                : client.ClientName || client.EventCategory}
                                        </p>

                                        <div className={styles.Deatailing}>
                                            <div className={styles.DeatailingInside}>
                                                <p>{`${client.EventName}`}</p>
                                                <p>{`${client.Time}`}</p>
                                            </div>
                                            <div>
                                                <AiOutlineDelete onClick={(e) => { e.stopPropagation(); confirmDelete(client._id) }} className={styles.deleteIcon} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
            )}

            {/* Confirmation Modal */}
            {modalOpen && (
                <div className={styles.modalOverlay} onClick={() => setModalOpen(false)}>
                    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                        <p>Are you sure you want to delete this event?</p>
                        <div className={styles.modalActions}>
                            <button onClick={() => setModalOpen(false)} className={styles.cancelBtn}>Cancel</button>
                            <button onClick={handleDelete} className={styles.confirmBtn}>Delete</button>
                        </div>
                    </div>
                </div>
            )}


        </>
    );
}


export default ClientList;
