import React,{useState} from 'react';
import photo1 from '../img/pexels-andrea-piacquadio-774909.jpg';
import photo2 from '../img/pexels-stefan-stefancik-91227.jpg';
import photo3 from '../img/user.png';

import { Link } from 'react-router-dom';
import { BiHomeAlt2 } from 'react-icons/bi';
import { GrProjects } from 'react-icons/gr';
import { TbPigMoney } from 'react-icons/tb';
import { BsGraphUpArrow } from 'react-icons/bs';
import { AiOutlineTeam } from 'react-icons/ai';
import { FiHelpCircle } from 'react-icons/fi';
import { AiOutlineSetting } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './SideTab.module.css';

function SideTab({ activeTab, clickTab }) {


  const navigate = useNavigate();
  const userStatus = useSelector(state => state);  const [projectselect, setprojectselect] = useState(true);
  const clicktabProfile = () => {
    navigate('/services', {
      state: {
        team: false, home: false, project: false,
        help: false,
        status: false,
        setting: false,
        finance: false,
        profile:true,
        active: 'profile',
        hover: false
      },
    });
  };
   
  const clicktab = () => {
    navigate('/services', {
      state: {
        team: false, home: true, project: false,
        help: false,
        status: false,
        setting: false,
        finance: false,
        active: 'home',
        profile:false,
        hover: false
      },
    });
  };

  const clicktabproj = () => {
    navigate('/services', {
      state: {
        team: false, home: false, project: true,
        help: false,
        status: false,
        setting: false,
        finance: false,
        profile:false,
        active: 'project',
        hover: false
      },
    });
  };

  const clicktabFin = () => {
    navigate('/services', {
      state: {
        team: false, home: false, project: false,
        help: false,
        status: false,
        setting: false,
        finance: true,
        profile:false,
        active: 'financials',
        hover: false
      },
    });

  };

  const clicktabStat = () => {
    navigate('/services', {
      state: {
        team: false, home: false, project: false,
        help: false,
        status: true,
        setting: false,
        profile:false,
        finance: true,
        active: 'status',
        hover: false
      },
    });
  };

  const clicktabTeam = () => {
    navigate('/services', {
      state: {
        team: true, home: false, project: false,
        help: false,
        status: true,
        setting: false,
        profile:false,
        finance: false,
        active: 'team',
        hover: false
      },
    });
  };
  const clicktabSetting = () => {
    navigate('/services', {
      state: {
        team: false, home: false, project: false,
        help: false,
        status: true,
        setting: true,
        profile:false,
        finance: false,
        active: 'settings',
        hover: false
      },
    });
  };
  const clicktabHelp = () => {
    navigate('/services', {
      state: {
        team: false, home: false, project: false,
        help: true,
        status: true,
        setting: true,
        profile:false,
        finance: false,
        active: 'help',
        hover: false
      },
    });
  };

  const displayName = userStatus.user.me.businessName 
  ? userStatus.user.me.businessName 
  : (userStatus.user.me.email  ? userStatus.user.me.email .split('@')[0] : 'User');

  return (
      <div className={styles.sidetab}>
         <div className={`${styles.topProfile} `} onClick={clicktabProfile}>
          <div className={styles.profilepicture}>
            <img src={photo3} alt='profile-pic' />
          </div>
          <div> 
          <p>{displayName}</p>
          </div>
        </div>
        <div className={styles.smalltabs}>
          <div className={`${styles.eachtab} ${activeTab === 'home' ? styles.activeTab : ''}`} onClick={clicktab}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <BiHomeAlt2 />
              </span>{' '}
              <Link>
                Home
              </Link>
            </li>
          </div>
          <div className={`${styles.eachtab} ${activeTab === 'project' ? styles.activeTab : ''}`} onClick={clicktabproj}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <GrProjects />
              </span>{' '}
              <Link>
                Projects
              </Link>
            </li>
          </div>
          {/* <div className={`${styles.eachtab} ${activeTab === 'financials' ? styles.activeTab : ''}`} onClick={clicktabFin}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <TbPigMoney />
              </span>{' '}
              <Link>
                Financials
              </Link>
            </li>
          </div> */}
          {/* <div className={`${styles.eachtab} ${activeTab === 'status' ? styles.activeTab : ''}`} onClick={clicktabStat}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <BsGraphUpArrow />
              </span>{' '}
              <Link>
                Status
              </Link>
            </li>
          </div> */}
          {/* <div className={`${styles.eachtab} ${activeTab === 'team' ? styles.activeTab : ''}`} onClick={clicktabTeam}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <AiOutlineTeam />
              </span>{' '}
              <Link>
                Team
              </Link>
            </li>
          </div> */}
          <div className={`${styles.eachtab} ${activeTab === 'settings' ? styles.activeTab : ''}`} onClick={clicktabSetting}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <AiOutlineSetting />
              </span>{' '}
              <Link>
                Settings
              </Link>
            </li>
          </div>
          <div className={`${styles.eachtab} ${activeTab === 'help' ? styles.activeTab : ''}`} onClick={clicktabHelp}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <FiHelpCircle />
              </span>{' '}
              <Link>
                Help
              </Link>
            </li>
          </div>
        </div>
    </div>
  );
}

export default SideTab;
