// FaceRecognitionGallery.jsx

import FaceImageViewer from './FaceImageViewer'
import React, { useState, useEffect } from 'react';
import { IoMdHeartEmpty, IoMdHeart, IoMdDownload } from 'react-icons/io';
import styles from './FaceRecognition_Gallery.module.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const FaceRecognitionGallery = () => {
  const { id } = useParams();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [favoriteImages, setFavoriteImages] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Utility functions
  const sanitizeFilename = (name) => {
    return name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  };

  const getFileExtension = (mimeType) => {
    const mimeMap = {
      'image/jpeg': '.jpg',
      'image/png': '.png',
      'image/gif': '.gif',
      // Add more mappings as needed
    };
    return mimeMap[mimeType] || '';
  };

  // Fetch matched images when component mounts or id changes
  useEffect(() => {
    const fetchMatchedImages = async () => {
      if (!id) {
        setError('No Guest ID provided.');
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get('https://api.hapzea.com/api/v1/user/matched-images', {
          params: { id },
        });

        console.log('Matched Images Response:', response.data);

        if (response.data && response.data.matchedImages && response.data.matchedImages.length > 0) {
          const fetchedImages = response.data.matchedImages.map((match, index) => ({
            id: `${match.guestId}-${index}`,
            thumbnail: match.imageUrl,
            original: match.imageUrl,
            title: `Guest_${sanitizeFilename(match.guestName)}`,
            description: `Confidence: ${(match.confidence * 100).toFixed(2)}%`, // Assuming confidence is between 0 and 1
          }));
          setImages(fetchedImages);
        } else {
          setImages([]);
          setError('No matched images found.');
        }
      } catch (err) {
        console.error('Error fetching matched images:', err);
        setError('Failed to load matched images.');
      } finally {
        setLoading(false);
      }
    };

    fetchMatchedImages();
  }, [id]);

  // Toggle favorite status
  const toggleFavorite = (id) => {
    setFavoriteImages((prev) =>
      prev.includes(id) ? prev.filter((favId) => favId !== id) : [...prev, id]
    );
  };

  // Download image
  const downloadImage = (url, title) => {
    try {
        console.log('Attempting to download image:', url, title);
        const link = document.createElement('a');
        link.href = url;

        // Extract the file extension from the URL or default to .jpg
        const extensionMatch = url.match(/\.(jpg|jpeg|png|gif)(\?|$)/);
        const extension = extensionMatch ? extensionMatch[1] : 'jpg';

        // Sanitize the filename to prevent issues
        const sanitizedTitle = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();

        link.download = `${sanitizedTitle}.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error during image download:', error);
        alert('Failed to download the image. Please try again later.');
    }
};


  // Open ImageViewer
  const openViewer = (index) => {
    setCurrentIndex(index);
  };

  // Close ImageViewer
  const closeViewer = () => {
    setCurrentIndex(-1);
  };

  return (
    <div className={styles.galleryContainer}>
      <div className={styles.galleryHeader}>
        <h1 className={styles.galleryTitle}>Explore Your Gallery</h1>
      </div>

      {loading && <p className={styles.loading}>Loading matched images...</p>}
      {error && <p className={styles.error}>{error}</p>}

      {!loading && !error && images.length === 0 && (
        <p className={styles.noImages}>No matched images found for this guest.</p>
      )}

      <div className={styles.galleryGrid}>
        {images.map((image, index) => (
          <div key={image.id} className={styles.galleryItem}>
            <div className={styles.imageWrapper} onClick={() => openViewer(index)}>
              <img
                src={image.thumbnail}
                alt={image.title}
                className={styles.galleryImage}
                loading="lazy"
              />
            </div>
            <div className={styles.actions}>
              <button
                className={styles.actionButton}
                onClick={() => toggleFavorite(image.id)}
                aria-label="Favorite"
              >
                {favoriteImages.includes(image.id) ? (
                  <IoMdHeart className={styles.favoriteIconActive} />
                ) : (
                  <IoMdHeartEmpty className={styles.favoriteIcon} />
                )}
              </button>
              <button
                className={styles.actionButton}
                onClick={() => downloadImage(image.original, image.title)}
                aria-label="Download"
              >
                <IoMdDownload className={styles.downloadIcon} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {currentIndex >= 0 && (
        <FaceImageViewer
          images={images.map((img) => ({
            url: img.original,
            alt: img.description || img.title,
          }))}
          currentIndex={currentIndex}
          onClose={closeViewer} 
          favoriteImages={favoriteImages}
          toggleFavorite={toggleFavorite}
        />
      )}
    </div>
  );
};

export default FaceRecognitionGallery;


