// src/components/FaceRecognition.jsx

import React, { useState, useRef, useEffect } from 'react';
import './FaceRecognition.css';
import { useParams, useNavigate, NavLink, Outlet } from 'react-router-dom';
import backBtn from '../img/backBtn.svg';
import { QRCodeCanvas } from 'qrcode.react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClients } from '../Store/clientSlice'; // Adjust the path as necessary
import axios from 'axios'; // Import Axios
import { UserActions } from '../Store/userSlice'; // Ensure correct import
import { GrHomeRounded } from "react-icons/gr";
// import ProfileComponent from './ProfileComponent';
import { IoReturnUpBackSharp } from "react-icons/io5";
import photo3 from '../img/user.png';
import UserProfile from './UserProfile';

export default function FaceRecognition() {
  const navigate = useNavigate();
  const { id } = useParams(); // Extract 'id' from URL
  const dispatch = useDispatch();

  // Select clients state from Redux store
  const clientsState = useSelector((state) => state.clients);
  const { loading, error, clients } = clientsState;

  // Select user state from Redux store
  const userState = useSelector((state) => state.user);
  const { userPhotoSelect, uploads, totalUploads } = userState;

  const [showQRCode, setShowQRCode] = useState(false);
  const [publishDisabled, setPublishDisabled] = useState(false); // State to disable publish button

  // State variables for Compare Guest Faces
  const [compareLoading, setCompareLoading] = useState(false);
  const [compareSuccess, setCompareSuccess] = useState(false);
  const [compareError, setCompareError] = useState(null);

  const [showProfile, setShowProfile] = useState(false); // State to show Profile

  const qrRef = useRef(null);

  // Fetch clients when component mounts
  useEffect(() => {
    dispatch(fetchClients());
    // If you have actions to fetch images and guests, dispatch them here
    // For example:
    // dispatch(fetchImages(id));
    // dispatch(fetchGuests(id));
  }, [dispatch, id]);

  // Find the specific client based on 'id'
  const client = clients.find((client) => client._id === id);

  const GoBack = () => {
    navigate('/services', {
      state: {
        team: false,
        home: false,
        project: true,
        help: false,
        status: false,
        setting: false,
        finance: false,
        active: 'project',
        hover: false,
      },
    });
  };

  const handlePublish = () => {
    if (!id) {
      alert('No event ID available to generate QR Code.');
      return;
    }
    setShowQRCode(true);
    setPublishDisabled(true); // Disable the publish button after publishing
  };

  const handleCloseEvent = async () => {
    const confirmClose = window.confirm('Are you sure you want to close this event?');
    if (!confirmClose) return;
  
    try {
      const response = await axios.post(
        `https://api.hapzea.com/api/v1/user/send-matching-images`, // Adjust to the correct backend endpoint
        { eventId: id }, // Send eventId in the body of the request
        {
          withCredentials: true, // Include cookies for authentication if necessary
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      alert('Event Closed Successfully!');
      // navigate('/home');
    } catch (error) {
      console.error('Close Event Error:', error);
      alert(`Error closing event: ${error.response?.data?.message || error.message}`);
    }
  };

  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = url;
      a.download = `qr_code_${id}.png`; // Use 'id' in the filename
      a.click();
    }
  };

  // Handle Compare Guest Faces
  const handleCompareGuestFaces = async () => {
    if (!id) {
      setCompareError('No event ID provided.');
      return;
    }

    setCompareLoading(true);
    setCompareError(null);
    setCompareSuccess(false);
    try {
      const response = await axios.post(
        `https://api.hapzea.com/api/v1/user/compare-guest-faces`, // Correct backend URL with '/me'
        {}, // Request body (if needed)
        {
          params: { eventId: id }, 
          withCredentials: true, // Send cookies with the request
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setCompareSuccess(true);
      console.log('Compare Response:', response.data);
      dispatch(UserActions.setCompareSuccess(true));
      // You can handle the response data as needed, e.g., navigate to a results page
    } catch (error) {
      setCompareError(error.response?.data?.message || 'Failed to compare guest faces.');
      console.error('Compare Error:', error);
    } finally {
      setCompareLoading(false);
    }
  };

  // Construct the dynamic event URL using 'id'
  const baseURL = process.env.REACT_APP_BASE_URL || 'https://hapzea.com';
  const eventURL = id
    ? `${baseURL}/${id}/ai/face_recognition/scan-qr-selfie-upload`
    : baseURL;

  // Click Handlers
  const handleHomeClick = () => {
    navigate('/services');
  };

  const handleProfileClick = () => {
    setShowProfile(true);
  };

  const handleCloseProfile = () => {
    setShowProfile(false);
  };

  return (
    <div className='face_reco_container'>
      <div className='face_reco_layer'>
        <div className='face_reco_sidebar'>
          {/* Sidebar content */}
          <div className='face_reco_profile'>
            <span onClick={handleProfileClick} style={{ cursor: 'pointer' }}>

              <img src={photo3} alt='profile-pic' />

            </span>
          </div>
          <div className='face_reco_Home' onClick={handleHomeClick} style={{ cursor: 'pointer' }}>
            <span><GrHomeRounded /> Home</span>
          </div>
        </div>

        {/* Conditional Rendering: Show Profile or Main Content */}
        {showProfile ? (
          // <div onClose={handleCloseProfile}></div>

          <div className='Prof_page'>
            <div className='Prof_page_back'>
              <div className='Prof_page_back_inner'>
                <IoReturnUpBackSharp onClick={handleCloseProfile} />
              </div>
            </div>
            <UserProfile />
          </div>

        ) : (
          <div className='face_reco_main'>
            <div className='clientTitle'>
              <div className='topClients'>
                <span onClick={GoBack} style={{ cursor: 'pointer' }}>
                  <img src={backBtn} alt="Back" />
                </span>
                {/* Display ClientName or fallback */}
                {loading ? (
                  <div className="spinner"></div>
                ) : error ? (
                  <span className="error-message">Error: {error}</span>
                ) : client ? (
                  <span>{client.ClientName || 'Unknown Client'}</span>
                ) : (
                  <span>Client Not Found</span>
                )}
              </div>
            </div>

            {/* Navbar with styled NavLink */}
            <div className="navbar">
              <NavLink
                to="upload"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                Image Upload
              </NavLink>
              <NavLink
                to="gallery"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                Gallery
              </NavLink>
              <NavLink
                to="guests"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                Guests
              </NavLink>
            </div>

            {/* Pass the 'id' via Outlet context if needed */}
            <div className="content-container">
              {loading ? (
                <p>Loading content...</p>
              ) : error ? (
                <p>Error loading content.</p>
              ) : client ? (
                <Outlet context={{ id }} />
              ) : (
                <p>Client data not available.</p>
              )}
            </div>
          </div>
        )}

        {!showProfile && (
          <div className='face_reco_rightbar'>
            {/* "Compare Guest Faces" Button (Enabled by Default) */}
            <button
              onClick={handleCompareGuestFaces}
              className='compare-button'
              aria-label="Compare Guest Faces"
              disabled={compareLoading || compareSuccess} // Disable if loading or comparison is successful
            >
              {compareLoading ? 'Generating...' : 'Generate matching images'}
            </button>


            {/* Show success or error messages */}
            {compareSuccess && (
              <span className="success-message">Faces compared successfully!</span>
            )}
            {compareError && (
              <span className="error-message">Error: {compareError}</span>
            )}

            {/* Existing Publish Button */}
            <button
              onClick={handlePublish}
              className='publish-button'
              aria-label="Publish Event"
              disabled={publishDisabled || loading || error || !client} // Disable if loading, error, or client not found
            >
              Publish
            </button>

            {showQRCode && (
              <div className="qr-code-container" ref={qrRef} onClick={downloadQRCode}>
                <QRCodeCanvas
                  value={eventURL} // Using updated eventURL with dynamic 'id'
                  size={150}
                  includeMargin={true}
                />
                <p className="qr-code-text">Click QR Code to Download</p>
              </div>
            )}

            <button
              onClick={handleCloseEvent}
              className='close-event-button'
              aria-label="Close Event"
            >
              Send
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
