import React, { useState, useEffect } from 'react';
import styles from './Albums.module.css'; // Import styles from your CSS Module
import { NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom';
import backBtn from '../img/backBtn.svg';
import album from '../img/albumsec.svg';
import locations from '../img/location.svg';
import Calender from '../img/Calender.svg';
import userstar from '../img/userstar.svg';
import editPen from '../img/editPen.svg';
import Lock from '../img/Lock.svg';
import Limit from '../img/Limit.svg';
import folder from '../img/folder.svg';
import share from '../img/Shares.svg';
import Download from '../img/Download.svg';
import CloudDownload from '../img/CloudDownload.svg';
import Unavailable from '../img/Unavailable.svg';
import ShareModal from './ShareModal/ShareModal';
import { useSelector } from 'react-redux';
import { UserActions } from '../Store/userSlice';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import './Albums.css';
import ShareAlbum from './ShareModal/ShareAlbumpage';

function Albums(props) {
  const [progress, setProgress] = useState(0);

  const location = useLocation();
  const userStatus = useSelector(state => state.user);
  const fetchedFiles = useSelector(state => state.user.Album);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { OnClient } = props;
  const { medium, select, id } = OnClient || {};
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showSelected, setSelected] = useState('');
  const [designpage, setDesignPage] = useState(false)
  const [filefetch, setFilefetch] = useState([])

  //  states for share album link by nived
  const [showShareAlbum, setShowShareAlbum] = useState(false);

  const [eventData, setEventData] = useState({
    _id: '',
    userId: '',
    EventCategory: '',
    Groom: '',
    Bride: '',
    EventName: '',
    Venue: '',
    ClientName: '',
    Email: '',
    Date: '',
    Source: '',
    Phone: '',
    magicLink: '',
    __v: 0,
  });


  const fetchData = async (id) => {
    try {
      const response = await axios.get('https://api.hapzea.com/api/v1/user/getFiles', {
        params: {
          _id: id
        },
        withCredentials: true,
      });
      const { album, photo } = response.data.data;
      setFilefetch(album);
      console.log('$$$$$$$$$$$$$$');
      console.log({ album, photo });
      const main = { album, photo }
      dispatch(UserActions.firstfiles([album, photo]));
      return main
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const compressImage = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0, img.width, img.height);

          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() }));
            },
            'image/jpeg',
            0.7
          );
        };
      };

      reader.readAsDataURL(file);
    });
  };



  const uploadFiles = async (file, endpoint, id) => {
    try {
      const formData = new FormData();
      const compressedFile = await compressImage(file);
      formData.append('photos', compressedFile);  // Ensure 'photos' matches the server's expected field name

      const url = `https://api.hapzea.com/api/v1/user/${endpoint}`;
      const params = id ? { id } : {};

      const response = await axios.post(url, formData, {
        withCredentials: true,
        params,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        console.log('Files uploaded successfully.');
      } else {
        console.error('Error uploading files:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading files:', error.message);
    }
  };



  const handleFreeFileUpload = async (event) => {
    const file = event.target.files[0];
    await uploadFiles(file, 'uploadResponsiveCoverPhoto', id); // Pass id here
    console.log('Selected file:', file);
  };

  const handleCoverFileUpload = async (event) => {
    const file = event.target.files[0];
    await uploadFiles(file, 'uploadCoverPhoto', id); // Pass id here
    console.log('Selected file:', file);
  };


  useEffect(() => {
    fetchData(id);
    const designPart = location.pathname.split("/").pop();
    setDesignPage(designPart == 'design');
    console.log('down');
    console.log(fetchedFiles);
    console.log(filefetch);
  }, [location, id])


  useEffect(() => {
    const replaceUserAsync = async (clientData) => {
      return new Promise((resolve, reject) => {
        dispatch(UserActions.replaceUser(clientData));
        resolve();
      });
    };

    const fetchData = async () => {
      try {

        // console.log(id);
        if (id !== undefined) {
          const response = await axios.get(`https://api.hapzea.com/api/v1/user/client/${id}`, {
            withCredentials: true,
          });

          console.log('client using ID')
          console.log(response.data.data);
          await replaceUserAsync(response.data.data.client);
          // setNewFolderAlbums(response.data.data.Album);
          // setNewFolderPhotoselection(response.data.data.Selection);
        }
      } catch (error) {

      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    console.log('from Store');
    setEventData(userStatus.user);
    setSelected(userStatus.selected);
    console.log('&*&*&*&*&*&*');
    console.log(userStatus.selected.selection);
  }, [userStatus]);


  const toggleSharePopup = () => {
    setShowSharePopup(!showSharePopup);
  };

  const cancelSharePopup = () => {
    setShowSharePopup(false)
  }

  const shareMedia = async (selectedFolders, id) => {
    console.log('SELECTED FOLDERS @@@@@@');
    console.log(selectedFolders);
    setShowSharePopup(false);
    console.log('$$');
    console.log(id);
    if (id !== undefined) {
      try {
        const response = await axios.post(
          `https://api.hapzea.com/api/v1/user/meta/${id}`,
          {
            selected: selectedFolders
          },
          {
            withCredentials: true,
          }
        );
        console.log(response.status);
      } catch (error) {
        console.log(' 55555555555555555555555555555555');
        console.error('Error sharing media:', error);
        // Handle error if needed
      }
    }
    // if (id !== undefined) {
    //   try {
    //     const response = await axios.get(
    //       `https://api.hapzea.com/api/v1/user/metacheck/${id}`,
    //       {
    //         withCredentials: true,
    //       }
    //     );
    //     console.log(response);
    //   } catch (error) {
    //     console.error('Error sharing media:', error);
    //     // Handle error if needed
    //   }
    // }
  };



  console.log("From Albums.js **************  ");
  console.log(medium);

  const GoBack = () => {
    navigate(`/events/${eventData._id}`);
  }


  ////  nived 

  // Toggle function for ShareAlbum component
  const toggleShareAlbum = () => {
    setShowShareAlbum(!showShareAlbum);
  };

  const clearError = () => {
    // Implement logic to clear errors
  };



  return (
    <div className="clientTitle">

      <div className="topClients">
        <span onClick={GoBack}>
          <img src={backBtn} alt="Back" />
        </span>
        {userStatus.user.ClientName}
      </div>

      <div className="wholePart">
        <div className="leftPart">
          <div className="albumTitle">
            <img src={album} alt="Album" />
            <p>{medium === 'choose' ? 'Photo Selection' : 'Albums'}</p>
          </div>
          <div className="Horizontalinks">
            {medium === 'choose' ? (
              <nav>
                <NavLink
                  to={`/uploads/${eventData.ClientName}/${eventData.Date}/${id}/${medium}/folders/${select !== undefined ? select : showSelected.selection !== undefined ? showSelected.selection : 'Full%20Photos'}`}
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                  end
                >
                  Folders
                </NavLink>

                <NavLink to={`/uploads/${eventData.ClientName}/${eventData.Date}/${id}/${medium}/selectedPhotos`}>
                  Selected Photos
                </NavLink>
              </nav>
            ) : (
              <nav>
                <NavLink
                  to={`/uploads/${eventData.ClientName}/${eventData.Date}/${id}/${medium}/folders/Full Photos`}
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                  end
                >
                  Folders
                </NavLink>
                <NavLink to={`/uploads/${eventData.ClientName}/${eventData.Date}/${id}/${medium}/design`}>
                  Design
                </NavLink>
                {/* <NavLink to={`/uploads/${eventData.ClientName}/${eventData.Date}/${id}/${medium}/hidden_photos`}>
                  Hidden Photos
                </NavLink> */}
              </nav>
            )}
          </div>

          <Outlet />
        </div>
        <div className="rightPart">
          {medium === 'choose' ?
            <div className='Share'>
              <div className='ShareBtn' onClick={toggleSharePopup}>Share <span><img src={share} /></span> </div>
            </div> : ''}
          {!designpage ? <div className="rightSectionOne">
            <div className="Contents">
              <p>Details</p>
              <div className="ContentsInside">
                <p><span><img src={locations} /></span>{`${eventData.Venue}`}</p>
                <p><span><img src={Calender} /></span>{`${eventData.Date}`}  </p>
                {/* <p><span><img src={userstar} /></span>{`${eventData.ClientName}`}</p>
                <p><span></span>Team</p>
                <p><span></span></p> */}
                {/* <div className='share-info'><p>**share your album page through email</p></div> */}
                <div className="financialsBtn" onClick={toggleShareAlbum}>

                  Share Album
                </div>
              </div>
            </div>
          </div>


            :
            <div className={styles.designOptions}>
              <div className={styles.wrapper}>
                <div className={styles.one}>
                  <p>Colour</p>
                </div>
                <div className={styles.two}>
                  <div className={styles.colorOne}>
                    <p>primary</p>
                    <div className={styles.circle}></div>
                  </div>
                  <div className={styles.colorTwo}>
                    <p>secondary</p>
                    <div className={styles.circle}></div>
                  </div>
                  <div className={styles.colorThree}>
                    <p>tertiary</p>
                    <div className={styles.circle}></div>
                  </div>
                </div>
                <div className={styles.three}>
                  <p>Fonts</p>
                </div>
                <div className={styles.four}>
                  <p>Primary</p>
                  <p>secondary</p>
                </div>
              </div>

              <div className={styles.uploadContainer}>
                <div className={`${styles.uploadButton} ${styles.firstUploadButton}`}>
                  <label htmlFor="coverPhotoInput">Upload Cover Photo for mobile</label>
                  <input
                    type="file"
                    id="coverPhotoInput"
                    style={{ display: "none" }}
                    accept="image/*"
                    multiple={false}
                    onChange={handleCoverFileUpload} // Call handleFileUpload function when a file is selected
                  />
                </div>
                <div className={`${styles.uploadButton} ${styles.secondUploadButton}`}>
                  <label htmlFor="responsiveCoverPhotoInput">Upload Responsive Cover Photo for Web</label>
                  <input
                    type="file"
                    id="responsiveCoverPhotoInput"
                    style={{ display: "none" }}
                    accept="image/*"
                    multiple={false}
                    onChange={handleFreeFileUpload} // Call handleFileUpload function when a file is selected
                  />
                </div>
              </div>

              <div className={styles.FoldersToDisplay}>
                <p>Folders to display</p>
                <div className={styles.DesignFoldersWrapper}>

                  {filefetch.map((file, index) => (
                    <div key={index} className={styles.DesignFolders}>
                      <img src={folder} alt="Folder" />
                      <span>{file}</span>
                    </div>
                  ))}

                </div>
              </div>
            </div>
          }
          {/* {!designpage ? <div className="permissions">
            <div className='Contents'>
              <p>Permissions</p>
              <div>
                <ul>
                  <li><a><img src={Lock} /></a></li>
                  <li><a><img src={Download} /></a></li>
                  <li><a><img src={CloudDownload} /></a></li>
                  <li><a><img src={Unavailable} /></a></li>
                </ul>
              </div>
            </div>
          </div> : ''} */}
          {/* {medium === 'choose' ?
            <div className='SelectionSettings'>
              <div className='Contents'>
                <p>Selection Settings</p>
                <div className='Security'>
                  <p><span>***</span> Security Pin <span>9836</span></p>
                  <img className='pen' src={editPen}></img>
                </div>
                <div className='Limit'>
                  <img src={Limit}></img>
                  <p>Selection Limit <span>100</span></p>
                  <img className='pen' src={editPen}></img>
                </div>
              </div>
            </div> : ''} */}
        </div>

        {showShareAlbum && (
          <ShareAlbum
            onCancel={toggleShareAlbum}
            clearError={clearError}
          />
        )}
      </div>

      {showSharePopup && (
        <div className='sharex'>
          <div className='sharenone'>
            <ShareModal
              onCancel={cancelSharePopup}
              onConfirm={shareMedia}
            />
          </div>
        </div>
      )}

    </div>

  );
}

export default Albums;