import React, { useEffect } from 'react';
import photo1 from '../img/pexels-andrea-piacquadio-774909.jpg';
import photo2 from '../img/pexels-stefan-stefancik-91227.jpg';
import photo3 from '../img/user.png';

import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { BiHomeAlt2 } from 'react-icons/bi';
import { GrProjects } from 'react-icons/gr';
import { TbPigMoney } from 'react-icons/tb';
import { BsGraphUpArrow } from 'react-icons/bs';
import { AiOutlineTeam } from 'react-icons/ai';
import { FiHelpCircle } from 'react-icons/fi';
import { AiOutlineSetting } from 'react-icons/ai';
import { BiSolidUserCheck } from 'react-icons/bi';
import AddEvent from '../componets/EventForm';
import styles from './Service.module.css'
import ProjectPage from './ProjectPage';
// import Financials from '../componets/Financials'; 
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../Store/userSlice';
import Status from '../componets/Status';
import UserProfile from './UserProfile';
import Settings from './Settings';

function Service({ props }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [userDta, setuserDta] = useState({}) 
  const userStatus = useSelector(state => state);
  const { state } = location;
  const { team, home, project, profile, help, status, setting, finance, active, hover } = state || {};

  const [clientList, setclientList] = useState([])

  useEffect(() => { 
    const userDetails = async () => {
      try {
        const response = await axios.get('https://api.hapzea.com/api/v1/user/me', { withCredentials: true });
        if (response) {
          console.log('FROM APP.JS');
          console.log(response.data.data);
          dispatch(UserActions.getMe(response.data.data.user));
          console.log('dispatched...........');
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    };
    userDetails();
  }, [dispatch]);

  useEffect(() => {
    const userDetails = async () => {
      try {
        const response = await axios.get('https://api.hapzea.com/api/v1/user/create/client', { withCredentials: true });
        if (response) {
          console.log('FROM SERVICE.JS');
          console.log(response.data.data.user.email);
          console.log(response.data.data.user.businessName);
          dispatch(UserActions.getMe(response.data.data.user));
          console.log('dispatched...........');
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    };
    userDetails();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('FROM ANDREA');
        console.log(typeof userStatus);
        console.log(userStatus.user);
        console.log(userStatus.me);
        console.log(userStatus.user.me.businessName);
        setuserDta(userStatus)
        console.log(userDta.me);
        console.log(userDta);
        const response = await axios.get('https://api.hapzea.com/api/v1/user/create/client', {
          withCredentials: true, 
        });

        const clientDetails = response.data.data.clients
        console.log(clientDetails);
        setclientList(clientDetails);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const [homeselect, sethomeselect] = useState(home !== undefined ? home : true);
  const [projectselect, setprojectselect] = useState(project !== undefined ? project : false);
  const [profileselect, setprofileselect] = useState(profile !== undefined ? profile : false);
  const [financeselect, setfinanceselect] = useState(finance !== undefined ? finance : false);
  const [statusselect, setstatusselect] = useState(status !== undefined ? status : false);
  const [teamselect, setteamselect] = useState(team !== undefined ? team : false);
  const [settingselect, setsettingselect] = useState(setting !== undefined ? setting : false);
  const [helpselect, sethelpselect] = useState(help !== undefined ? help : false);
  const [Homethrough, setfromHome] = useState(home !== undefined ? home : false);
  const [activeTab, setActiveTab] = useState(active !== undefined ? active : 'home');
  const [isHovered, setIsHovered] = useState(hover !== undefined ? hover : false);

  const clicktab = () => {
    setteamselect(false);
    sethomeselect(true);
    setprojectselect(false);
    sethelpselect(false)
    setstatusselect(false);
    setsettingselect(false);
    setfinanceselect(false);
    setprofileselect(false);
    setActiveTab('home')
    setIsHovered(false)
  };

  const clicktabProfile = () => {
    setprofileselect(true);
    setprojectselect(false);
    sethomeselect(false);
    setfinanceselect(false);
    setActiveTab('profile')
    setfromHome(false)
  };
  const clicktabproj = () => {
    setprojectselect(true);
    sethomeselect(false);
    setfinanceselect(false);
    setActiveTab('project')
    setfromHome(false)
    setprofileselect(false);
  };
  const clicktabFin = () => {
    setprojectselect(false);
    sethomeselect(false);
    setfinanceselect(true);
    // setActiveTab('financials')
    setfromHome(false)
    setprofileselect(false);
  };
  const clicktabStat = () => {
    setprojectselect(false);
    sethomeselect(false);
    setfinanceselect(false);
    setstatusselect(true);
    setActiveTab('status')
    setfromHome(false)
    setprofileselect(false);
  };
  const clicktabTeam = () => {
    setteamselect(true);
    setprojectselect(false);
    sethomeselect(false);
    setfinanceselect(false);
    setstatusselect(false);
    setActiveTab('team')
    setfromHome(false)
    setprofileselect(false);
  };
  const clicktabSetting = () => {
    setsettingselect(true);
    setteamselect(false);
    setprojectselect(false);
    sethomeselect(false);
    setfinanceselect(false);
    setstatusselect(false);
    setActiveTab('settings')
    setfromHome(false)
    setprofileselect(false);
  };
  const clicktabHelp = () => {
    sethelpselect(true);
    setsettingselect(false);
    setteamselect(false);
    setprojectselect(false);
    sethomeselect(false);
    setfinanceselect(false);
    setstatusselect(false);
    setActiveTab('help')
    setfromHome(false)
    setprofileselect(false);
  };

  const addnewclient = () => {
    setprojectselect(true);
    sethomeselect(false);
    setActiveTab('project')
    setfromHome(true)
  }

  const cancelingform = () => {
    setfromHome(false);
    window.scrollTo(0, 0);
  }

  const submissionTask = () => {
    setfromHome(false);
    window.scrollTo(0, 0);
  }

  const displayName = userStatus.user.me?.businessName 
    ? userStatus.user.me.businessName 
    : (userStatus.user.me?.email ? userStatus.user.me.email.split('@')[0] : 'User');

  return (
    <div className={styles.service}>
      <div className={styles.sidetab}>
        <div className={`${styles.topProfile} `} onClick={clicktabProfile}>
          <div className={styles.profilepicture}>
            <img src={photo3} alt='profile photo' />
          </div>
          <div>
            <p>{displayName}</p>
          </div>
        </div>
        <div className={styles.smalltabs}>
          <div className={`${styles.eachtab} ${activeTab === 'home' ? styles.activeTab : ''}`} onClick={clicktab}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <BiHomeAlt2 />
              </span>{' '}
              <Link>
                Home
              </Link>
            </li>
          </div>
          <div className={`${styles.eachtab} ${activeTab === 'project' ? styles.activeTab : ''}`} onClick={clicktabproj}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <GrProjects />
              </span>{' '}
              <Link>
                Projects
              </Link>
            </li>
          </div>
          {/* <div className={`${styles.eachtab} ${activeTab === 'financials' ? styles.activeTab : ''}`} onClick={clicktabFin}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <TbPigMoney />
              </span>{' '}
              <Link>
                Financials
              </Link>
            </li>
          </div> */}  
          {/* <div className={`${styles.eachtab} ${activeTab === 'status' ? styles.activeTab : ''}`} onClick={clicktabStat}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <BsGraphUpArrow />
              </span>{' '}
              <Link>
                Status
              </Link>
            </li>
          </div> */}
          {/* <div className={`${styles.eachtab} ${activeTab === 'team' ? styles.activeTab : ''}`} onClick={clicktabTeam}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <AiOutlineTeam />
              </span>{' '}
              <Link>
                Team
              </Link>
            </li>
          </div> */}
          <div className={`${styles.eachtab} ${activeTab === 'settings' ? styles.activeTab : ''}`} onClick={clicktabSetting}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <AiOutlineSetting />
              </span>{' '}
              <Link>
                Settings
              </Link>
            </li>
          </div>
          <div className={`${styles.eachtab} ${activeTab === 'help' ? styles.activeTab : ''}`} onClick={clicktabHelp}>
            <li>
              <span className={styles.icon} style={{ fontSize: '22px' }}>
                <FiHelpCircle />
              </span>{' '}
              <Link>
                Help
              </Link>
            </li>
          </div>
        </div>
      </div>
      <div className={styles.maintab}>
        {homeselect ? (
          <div className={styles.Home}>
            <div>
              <h2>Welcome, {userStatus.user.me?.businessName ? userStatus.user.me.businessName : userStatus.user.me?.email ? userStatus.user.me.email.split('@')[0] : 'User'}! 🎉</h2>
            </div>
            <div className={styles.first_client} 
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={addnewclient}
            >
              <span
                className={`${styles.least} ${isHovered ? styles.hovered : ''}`}
              >
                <BiSolidUserCheck style={{ fontSize: '25px', color: isHovered ? '#fff' : '#256fff' }} />
              </span>
              <p>Add Your First Client</p>
              <p></p>
            </div>
          </div>
        ) : (
          ''
        )}

        {projectselect ? (
          <div className={styles.Projects}>
            {Homethrough ? (
              <div>
                <AddEvent Oncancel={cancelingform} Onsub={submissionTask} />
              </div>
            ) : (
              <ProjectPage Onadd={addnewclient} />
            )}
          </div>
        ) : profileselect ? (
          <div className={styles.Profile}>
            <UserProfile />
          </div>
        ) 
        // : financeselect ? (
        //   <div className={styles.Finance}>
        //     <Financials />
        //   </div>
        // )
        //  : statusselect ? (
        //   <div className={styles.Status}>
        //     <Status />
        //   </div>
        // ) 
        // : teamselect ? (
        //   <div className={styles.Status}>
        //     <Status />
        //   </div>
        // )
         : settingselect ? (
          <div className={styles.Status}>
            <Settings />
          </div>
        )
         : helpselect ? (
          <div className={styles.Status}>
            <Status />
          </div>
        ) 
        : (
          null
        )}
      </div>
    </div>
  );
}

export default Service;
