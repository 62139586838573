import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import Card from './Card';
import Button from './Button';
import classes from './ShareModal.module.css';
import cross from '../../img/Share/cross.svg'
import email from '../../img/Share/email.svg'
import phone from '../../img/Share/phone.svg'
import folder from '../../img/Share/folder.svg'
import smallCross from '../../img/Share/smallCross.svg'
import { useSelector } from 'react-redux';
import { CiFolderOn } from "react-icons/ci";
// import { UserActions } from '../Store/userSlice';
// import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onCancel} />;
};

const ModalOverlay = (props) => {
  const userStatus = useSelector(state => state.user);
  const [clientName, setClientName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    setClientName(userStatus.user.ClientName);
    setClientPhone(userStatus.user.Phone);
    setSelectedFolders(userStatus.PhotoSelection);
    setClientEmail(userStatus.user.Email);
  }, [userStatus]);

  const notifySuccess3 = () => toast.success('Successfully sent photo selection page!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyError3 = () => toast.error('Failed to send photo selection page', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const removeFolder = (indexToRemove) => {
    setSelectedFolders(prevFolders => prevFolders.filter((_, index) => index !== indexToRemove));
  };

  const handleInputFocus = () => {
    props.clearError();
  };

  const handleConfirm = async () => {
    setIsSending(true);
    props.onConfirm(selectedFolders, userStatus.user._id);
    try {
      const { user } = userStatus;
      const Magic = user.magicLink;
      const parts = Magic.split('/');
      const dynamicValue = parts[4];

      const requestBody = {
        magic_url: `https://hapzea.com/${dynamicValue}/${user._id}/just/Full Photos`,
        email: clientEmail,
        company_name: dynamicValue,
        clientId: user._id,
        event_name: user.EventName
      };

      const response = await axios.post('https://api.hapzea.com/api/v1/user/sendMedia', requestBody, { withCredentials: true });
      console.log('POST request successful:', response.data);
      notifySuccess3();
    } catch (error) {
      console.error('Error sending POST request:', error);
      notifyError3();
    }
    setIsSending(false);
  };
 
  return (
    <Card className={classes.modal}>
      <header className={classes.header}>
        <h3>Share</h3>
        <img onClick={props.onCancel} src={cross} alt="Close" />
      </header>
      <div className={classes.horizontalLine}></div>
      <div className={classes.wholeContent}>
        <div className={classes.ContentShell}>
          <div>
            <p>{clientName}</p>
          </div>
          <div className={classes.email}>
            <img src={email} alt="Email" />
            <p>{clientEmail}</p>
          </div>
          <div className={classes.contact}>
            <img src={phone} alt="Phone" />
            <p>{clientPhone}</p>
          </div>
        </div>
      </div>
      <div className={classes.wholeFolders}>
        {selectedFolders.map((folder, index) => (
          <div className={classes.folder} key={index}>
            <CiFolderOn className={classes.folderImg} />
            <p className={classes.folderName}>{folder}</p>
            <img className={classes.crossicon} src={smallCross} onClick={() => removeFolder(index)} alt="Remove" />
          </div>
        ))}
      </div>
      <footer className={classes.actions}>
        <button className={classes.cancel} onClick={props.onCancel}>Skip, Share later</button>
        <button className={classes.create} onClick={handleConfirm} disabled={isSending}>
          {isSending ? 'Sending...' : 'Share'}
        </button>
      </footer>

    </Card>
  );
};

const ShareModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop onCancel={props.onCancel} />,
        document.getElementById('backdrop-root')
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onCancel={props.onCancel}
          onConfirm={props.onConfirm}
        />,
        document.getElementById('overlay-root')
      )}
    </React.Fragment>
  );
};

export default ShareModal;
